.pose-container {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  font-family: 'Titillium Web', sans-serif;
  color: white;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transform-origin: 0px 0px;
  overflow: hidden;
  background-color: #596e73;
  width: 100%;
  max-height: 100%;

  video {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.selfie {
      transform: scale(-1, 1);
    }
  }

  canvas {
    width: 100%;
    display: block;
    position: relative;
    left: 0;
    top: 0;
  }

  .title h2 {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
    padding: 0;
    margin: 0;
    font-size: 2rem;
  }

  .count h2 {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 10;
    padding: 0;
    margin: 0;
    font-size: 8rem;
    line-height: 1;
    margin-right: 1rem;
    -webkit-text-stroke: 1px black;

  }

  .debug {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 10;
  }


  .control-panel {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .loading {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    backface-visibility: hidden;
    justify-content: center;
    opacity: 1;
    transition: opacity 1s;

    .message {
      font-size: x-large;
    }

    .spinner {
      position: absolute;
      width: 120px;
      height: 120px;
      animation: spin 1s linear infinite;
      border: 32px solid #bebebe;
      border-top: 32px solid #3498db;
      border-radius: 50%;
    }
  }

  .square-box {
    width: 33%;
    height: 0;
    padding-top: 33%;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .landmark-grid-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #99999999;
  }
}
