.squat-wrapper {
  color: white;
  .meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 20;

    .title h2 {
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 12;
      padding: 0;
      margin: 0;
      font-size: 2rem;
    }
  
    .count h2 {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      z-index: 12;
      padding: 0;
      margin: 0;
      font-size: 8rem;
      line-height: 1;
      margin-right: 1rem;
      -webkit-text-stroke: 1px black;
  
    }
  }

  .counter {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 20;
  }

}
