.page-wrapper {
    width: 100%;
    height: 100%;
}

.the-counter {
    position: fixed;
    color: white;
    bottom: 1rem;
    right: 1rem;
    z-index: 10;
    padding: 0;
    margin: 0;
    font-size: 8rem;
    line-height: 1;
    margin-right: 1rem;
    -webkit-text-stroke: 1px black;

    h2 {
        margin: 0;
        padding: 0;
    }
}